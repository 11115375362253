<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="570px"
      @close="close"
    >
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        :rules="rules"
        size="small"
        label-width="88px"
      >
        <el-form-item label="用户名" prop="name">
          <el-input
            style="width: 420px"
            placeholder="请输入用户名"
            v-model="form.name"
          />
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input
            style="width: 420px"
            placeholder="请输入电话"
            v-model.number="form.mobile"
          />
        </el-form-item>
        <el-form-item label="账号" prop="userId">
          <el-input
            style="width: 420px"
            placeholder="请输入账号"
            v-model="form.userId"
          />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            style="width: 420px"
            placeholder="请输入密码"
            v-model.number="form.password"
          />
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select
            v-model="form.roleId"
            style="width: 420px"
            placeholder="请选择"
            @remove-tag="deleteTag"
          >
            <el-option
              v-for="item in roleIdList"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="deptId">
          <treeselect
            v-model="form.deptId"
            :options="TreeList"
            style="width: 420px"
            placeholder="选择部门"
            :normalizer="normalizer"
          />
        </el-form-item>
        <el-form-item label="所属门店" prop="storeId">
          <el-select
            clearable 
            v-model="form.storeId"
            style="width: 420px"
            placeholder="未选择所属门店默认为全部门店"
            @remove-tag="deleteTag"
          >
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="close">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { sysRoleList, listOfPage2 } from "@/api/system/user";
import { isvalidPhone } from "@/utils/validate";
export default {
  data() {
    // 自定义验证
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      roles: [],
      dialogVisible: true,
      roleIdList: [], //角色列表,
      shopList: [], //门店列表
      title: "创建用户",
      form: {
        name: "", //	string	true	普通参数		用户姓名
        mobile: "", //	string	true	普通参数		手机号码
        roleId: "", //	string	true	普通参数		角色id
        deptId: null, //	string	true	普通参数		部門id
        userId: "", //账号
        password: "", //密码
        storeId: "", //所属门店
      },
      normalizer(node) {
        //去掉children=[]的children属性
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isDefaultExpanded: true,
        };
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        mobile: [{ required: true, trigger: "blur", validator: validPhone }],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        userId: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (JSON.stringify(this.selectItem) !== "{}") {
      let data = JSON.parse(JSON.stringify(this.selectItem));
      this.form = {
        name: data.name, //	string	true	普通参数		用户姓名
        mobile: data.mobile, //	string	true	普通参数		手机号码
        roleId:
          data.userRoleVos && data.userRoleVos[0]
            ? data.userRoleVos[0].roleId
            : "", //	string	true	普通参数		角色id
        deptId: data.deptId, //	string	true	普通参数		部門id
        id: data.id,
        storeId: data.storeVos && data.storeVos[0].id,
        userId: data.userId,
        password: data.password,
      };
      this.title = "编辑用户";
    }
    this.getList();
    this.getShopList();
  },
  props: {
    selectItem: {},
    TreeList: {},
  },
  methods: {
    getShopList() {
      listOfPage2().then((res) => {
        this.shopList = res.data.body;
      });
    },
    getList() {
      sysRoleList().then((res) => {
        // console.log(res.data.body);
        this.roleIdList = res.data.body;
      });
    },
    close() {
      this.$emit("close");
    },
    submit() {
      // console.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    deleteTag(value) {
      userRoles.forEach(function (data, index) {
        if (data.id === value) {
          userRoles.splice(index, value);
        }
      });
    },
  },
  components: { Treeselect },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
