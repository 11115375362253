<template>
  <div class="user">
    <!-- 顶部条件搜索区域 -->
    <div class="userTopSuch">
      <div class="flx-row ali-c">
        <el-input
          v-model="form.keyword"
          clearable
          size="small"
          placeholder="输入员工姓名/账号搜索"
          prefix-icon="el-icon-search"
          class="search-input"
        />
        <el-button
          type="primary"
          icon="el-icon-search"
          class="ml-15"
          @click="search"
          size="small"
          >查询</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="small"
          class="ml-15"
          @click="reset"
          >重置</el-button
        >
        <el-button size="small" type="primary" icon="" @click="listAdd()"
          >新增</el-button
        >
      </div>
    </div>
    <div class="userBody">
      <!-- 左侧树状图 -->
      <div class="leftTreeBox">
        <el-tree
          :data="TreeList"
          :props="defaultProps"
          default-expand-all
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <!-- 右侧列表及按钮操作区域 -->
      <div class="rightListBox">
        <!-- 表格 -->
        <el-table
          class="mt-24 member-table"
          :data="tableData"
          @selection-change="tableSelect"
        >
          <el-table-column
            type="index"
            label="序号"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="员工姓名" prop="name"></el-table-column>
          <el-table-column label="账号" prop="userId"></el-table-column>
          <el-table-column label="角色" prop="roleName"></el-table-column>
          <el-table-column label="部门" prop="deptName"></el-table-column>
          <el-table-column label="手机号码" prop="mobile"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="'1'"
                :inactive-value="'0'"
                active-text="启用"
                inactive-text="禁用"
                @change="upDownShelf(scope.row.status, scope.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="创建日期" prop="createTime"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="flx-row ali-c">
                <span class="tip-text-info" @click="listAdd(scope.row)">
                  <i class="el-icon-edit-outline"></i>
                  编辑
                </span>
                <span
                  class="ml-10 tip-text-delete"
                  @click="deleteCommodity(scope.row)"
                >
                  <i class="el-icon-delete"></i>
                  删除
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--  :type="'list'"-->
        <Pagination
          :total="total"
          :pageNum="form.pageNum"
          :pageSize="form.pageSize"
          :pageSizes="[5, 8, 10, 15]"
          
          @changePage="changePage" 
          @syncPageData="syncPageData"
        />
      </div>
    </div>
    <my-add
      v-if="myAddType"
      :selectItem="selectItem"
      :TreeList="TreeList"
      @submit="submit"
      @close="close"
    ></my-add>
  </div>
</template>

<script>
import {
  sysUserList,
  sysDeptList,
  upOrDown,
  sysRolePut,
  sysUser,
  sysUserPut,
  sysUserDel,
} from "@/api/system/user";
import Pagination from "@/components/Pagination/index";
import myAdd from "./mask/add";
export default {
  data() {
    return {

      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableData: [],
      form: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
        deptId: "", //部门id
      },
      total: 0,
      myAddType: false,
      selectItem: {},
    };
  },
  mounted() {
    this.getList();
    this.getTree();
  },
  methods: {
    deleteCommodity(row) {
      sysUserDel(row.id).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getList();
      });
    },
    submit(e) {
      if (JSON.stringify(this.selectItem) !== "{}") {
        //编辑
        sysUserPut(e).then((res) => {
          this.myAddType = false;
          this.selectItem = {};
          this.getList();
        });
      } else {
        sysUser(e).then((res) => {
          this.myAddType = false;
          this.selectItem = {};
          this.getList();
        });
      }
    },
    close() {
      this.myAddType = false;
      this.selectItem = {};
    },
    getTree(id) {
      sysDeptList((id = "")).then((res) => {
        this.TreeList = res.data.body;
      });
    },
    getList() {
      sysUserList(this.form).then((res) => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    handleNodeClick(data) {
      // console.log(data)
      this.form.deptId = data.id;
      this.getList();
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    //上下架
    async upDownShelf(status, id) {
      const data = {
        id,
        status: status == 0 ? 0 : 1,
      };
      upOrDown(data).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getList();
      });
    },
    search() {
       this.getList();
    },
    reset() {
      this.form = {
        pageNum:1,
        pageSize:10,
        keyword:"",
        deptId:""//部门id
      }
      this.getList();
    },
    changePage(type) {
      this.getList();
    },
    syncPageData(data) {
      console.log(data)
      this.form.pageNum = data.num;
       this.form.pageSize = data.size;
      this.getList();
    },
    listAdd(item) {
      if (item) {
        //编辑
        // console.log(item);
        this.selectItem = item;
      } else {
      }
      this.myAddType = true;
    },
  },
  components: {
    Pagination,
    myAdd,
  },
};
</script>

<style lang="scss" scoped>
.user {
  min-height: 80vh;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  .userTopSuch {
    padding-bottom: 15px;
    .search-input {
      width: 300px;
    }
  }
  .userBody {
    display: flex;
    .leftTreeBox {
      width: 300px;
      padding-top: 30px;
      /deep/.el-tree-node__label {
        font-size: 16px !important;
      }
    }
    .rightListBox {
      flex: 1;
    }
  }
}
</style>
